import { FormData } from './formData';

export enum Gender {
  F = 'Female',
  M = 'Male',
  O = 'Other',
  U = 'Unselected',
}

export enum Diagnosis {
  CN = 'Cognitive Normal',
  SCD = 'Suspected Cognitive Decline',
  MCI = 'Mild Cognitive Impairment',
  MAD = 'Mild AD',
  FD = 'Frontotemporal Dementia',
  LBD = 'Lewy Body Dementia',
  VD = 'Vascular Dementia',
  PD = 'Parkinson’s Disease',
  O = 'Other',
  U = 'Unselected',
}

export enum ApoEStatus {
  A4HO = 'A4 homozygous',
  A4HE = 'A4 heterozygous',
  NONC = 'Non-carrier',
  NYD = 'Not yet determined',
  U = 'Unselected',
}

export enum AmyloidStatus {
  ABP = 'A4 homozygous',
  ABN = 'A4 heterozygous',
  NYD = 'Not yet determined',
  U = 'Unselected',
}

export interface RegisterParticipantRequest {
  participantID: string;
  contentPackID: string;
  age: number;
  gender: Gender;
  diagnosis: Diagnosis;
  apoEStatus: ApoEStatus;
  amyloidStatus: AmyloidStatus;
  educationYears: number;
}

export const toRegisterParticipantRequest = (formData: FormData): RegisterParticipantRequest => {
  const participantID = formData.participantID;
  const contentPackID = formData.contentPackID;
  const age = parseInt(formData.age);
  const gender = formData.gender;
  const diagnosis = formData.diagnosis;
  const apoEStatus = formData.apoEStatus;
  const amyloidStatus = formData.amyloidStatus;
  const educationYears = parseInt(formData.educationYears);

  return {
    participantID,
    contentPackID,
    age,
    gender,
    diagnosis,
    apoEStatus,
    amyloidStatus,
    educationYears,
  };
};
